import { MissionType } from 'recoil/userInfo';
import { getCookie, setCookie } from './cookie';
import { beforeUnLoadFunction } from './tools';

export const superServerName =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SUPER_REAL_HOST
    : process.env.REACT_APP_SUPER_DEV_HOST;

export const serverName =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_REAL_HOST
    : process.env.REACT_APP_LOCAL === 'false'
    ? process.env.REACT_APP_DEV_HOST
    : process.env.REACT_APP_LOCAL_HOST;

export const checkTokenError = () => {
  window.removeEventListener('beforeunload', beforeUnLoadFunction);
  sessionStorage.removeItem('user');
  window.location.replace(`/login`);
  alert('세션이 종료되었습니다. 다시 로그인 바랍니다.');
  window.close();
};

function setToken(response: Response) {
  const authToken = response.headers.get('Authorization')?.substring(7);
  const refreshToken = response.headers.get('RefreshToken')?.substring(7);

  if (authToken) {
    setCookie('authToken', authToken);
  }

  if (refreshToken) {
    setCookie('refreshToken', refreshToken);
  }
}

function setHeader(no_content_type?: boolean) {
  const authToken = getCookie('authToken');
  const refreshToken = getCookie('refreshToken');
  const autoToken = localStorage.getItem('autoToken');

  const header: { [key: string]: string } = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (no_content_type) {
    delete header['Content-Type'];
  }

  if (authToken) {
    header['Authorization'] = `Bearer ${authToken}`;
  }

  if (refreshToken) {
    header['RefreshToken'] = `Bearer ${refreshToken}`;
  }

  if (autoToken) {
    header['AutoToken'] = `Bearer ${autoToken}`;
  }

  return header;
}

export const fetchSuperGetApi = function (uri: string) {
  return async () => {
    const request_url = superServerName + uri;
    const response = await fetch(request_url, {
      method: 'GET',
      credentials: 'include',
    });

    return response.json();
  };
};

export const fetchSuperPostApi = async function ({ uri, args = {} }: { uri: string; args?: object }) {
  const request_url = superServerName + uri;
  const response = await fetch(request_url, {
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};

export const fetchPostCFN = async function ({ uri, args = {} }: { uri: string; args?: object }) {
  const request_url = uri;
  const response = await fetch(request_url, {
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      ...setHeader(),
    },
  });

  if (response.status === 401) {
    checkTokenError();
  }

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  return response.json();
};

// fetch POST return response json
export const fetchPostApi = async function ({ uri, args = {} }: { uri: string; args?: object }) {
  const request_url = serverName + uri;
  const response = await fetch(request_url, {
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      ...setHeader(),
    },
    credentials: 'include',
  });

  if (response.status === 401) {
    checkTokenError();
  }

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  return response.json();
};

// fetch POST return response
export const fetchPostApiRaw = async function ({ uri, args = {} }: { uri: string; args?: object }) {
  const request_url = serverName + uri;
  const response = await fetch(request_url, {
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      ...setHeader(),
    },
    credentials: 'include',
  });

  if (response.status === 401) {
    checkTokenError();
  }

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  return response;
};

// fetch DELETE
export const fetchDeleteApi = async function ({ uri, args = {} }: { uri: string; args?: object }) {
  const request_url = serverName + uri;
  const response = await fetch(request_url, {
    method: 'DELETE',
    body: JSON.stringify(args),
    headers: {
      ...setHeader(),
    },
    credentials: 'include',
  });

  if (response.status === 401) {
    checkTokenError();
  }

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  return response;
};

// fetch PUT
export const fetchPutApi = async function ({ uri, args = {} }: { uri: string; args?: object }) {
  const request_url = serverName + uri;
  const response = await fetch(request_url, {
    method: 'PUT',
    body: JSON.stringify(args),
    headers: {
      ...setHeader(),
    },
    credentials: 'include',
  });

  if (response.status === 401) {
    checkTokenError();
  }

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  return response;
};

// fetch PATCH
export const fetchPatchApi = async function ({ uri, args = {} }: { uri: string; args?: object }) {
  const request_url = serverName + uri;
  const response = await fetch(request_url, {
    method: 'PATCH',
    body: JSON.stringify(args),
    headers: {
      ...setHeader(),
    },
    credentials: 'include',
  });

  if (response.status === 401) {
    checkTokenError();
  }

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  return response;
};

// fetch GET
export const fetchGetApi = function (uri: string) {
  return async () => {
    const request_url = serverName + uri;
    const response = await fetch(request_url, {
      method: 'GET',
      headers: {
        ...setHeader(),
      },
      credentials: 'include',
    });

    if (response.status === 401) {
      checkTokenError();
    }

    if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

    return response.json();
  };
};

// fetch POST Files
export const fetchFileApi = async function ({ uri, args }: { uri: string; args?: FormData }) {
  const request_url = serverName + uri;
  const response = await fetch(request_url, {
    method: 'POST',
    headers: {
      ...setHeader(true),
    },
    body: args,
    credentials: 'include',
  });

  if (response.status === 401) {
    checkTokenError();
  }

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  return response.json();
};

export const fetchExportApi = function (uri: string) {
  return async () => {
    const request_url = serverName + uri;
    const response: Response = await fetch(request_url, {
      method: 'GET',
      headers: {
        ...setHeader(),
      },
      credentials: 'include',
    });

    if (response.status === 401) {
      checkTokenError();
    }

    if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

    return response;
  };
};

// Fetch GET AUDIO FILES
export const fetchGetAudioApi = async function (uri: string) {
  try {
    const request_url = serverName + uri;
    const response = await fetch(request_url, {
      method: 'GET',
      headers: {
        ...setHeader(),
      },
      credentials: 'include',
    });

    if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

    const responseBlob = await response.blob();

    return responseBlob;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getMissionStepAndUpdateMissionInfo = async function (
  uri: string,
  callback?: (mission: MissionType) => void,
) {
  try {
    const response = await fetchGetApi(uri)();
    if (response.result && callback) {
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};
